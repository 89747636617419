.section.section-404 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 160px);
  background: #fff;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.section.section-404 .error-code {
  font-size: 4rem !important;
}
@media screen and (min-width: 992px) {
  .section.section-404 .error-code {
    font-size: 2.5rem !important;
  }
  .section.section-404 .bg-image-404 {
    width: 50% !important;
  }
}
@media screen and (min-width: 769px) {
  .bg-image-404 {
    width: 50% !important;
  }
}
@media screen and (max-width: 768px) {
  .section.section-404 .error-code {
    font-size: 3rem !important;
  }
  .section.section-404 .bg-image-404 {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .section.section-404 .error-code {
    font-size: 3rem !important;
  }
}
.m-0 {
  margin: 0 !important;
}
.m-5 {
  margin: 5px !important;
}
.nf-button {
  padding: 12px 20px !important;
}
