.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.MuiListItemIcon-root {
  min-width: 40px !important;
}
/* .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  min-height: 250px;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  min-height: 250px;
} */

.ck-editor__editable_inline {
  min-height: 350px;
  max-height: 650px;
}

.ck-content .table {
  width: 100%;
}
